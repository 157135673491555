<template>
  <div>
    <img class="card-img-top fluid" :src="category.image" :alt="category.name">
    <div class="card-body">
        <h6 class="h6 text-center py-2 mb-0">{{ category.name }}</h6>
    </div>
  </div>
</template>
<script>
import { mapGetters } from 'vuex'

export default {
  props: {
    category: {
      required: true,
      type: Object
    }
  },
  computed: {
    ...mapGetters([
      'lang',
      'console'
    ])
  }
}
</script>
