<template>
  <div class="row">
    <div class="col-4 col-sm-3 col-md-2 mb-3" v-for="product in products.data" :key="product.id">
      <div class="card products">
        <router-link class="" :to="{ name: 'ShopProduct', params: { lang: lang, console: console, slug: product.slug } }">
          <figure>
            <img class="card-img-top fluid" :src="product.image" :alt="product.image">
          </figure>
        </router-link>
        <div class="card-body">
          <h5 class="h6 text-center py-2 mb-0">{{ product.name }}</h5>
          <p class="mb-0 text-center d-flex align-items-center justify-content-center">
            <strong class="pt-1">{{ product.price_1 }}</strong>
            <img src="../../../assets/images/money.png" alt="Moneda itfa" class="ml-2 img-coin">
          </p>
        </div>
        <div class="card-footer" v-if="isAuthenticated && user.type === 'SUPER'">
          <b-button variant="primary" :to="{ name: 'ProductEdit', params: { lang: lang, console: console, productId: product.id } }"><i class="fa fa-pencil"></i></b-button>
          <b-dropdown right class="float-right" no-caret>
            <div slot="button-content">
              <i class="fa fa-trash px-1"></i>
            </div>
            <b-dropdown-form class="py-3">
            <b-dropdown-text class="font-weight-bold p-0">{{ $t('confirmTextDelete') }}</b-dropdown-text>
            <div class="w-100 mt-3">
              <b-button @click="closeAllDD()" variant="primary">{{ $t('not') }}</b-button>
              <b-button @click="destroy(product.id)" variant="danger" class="float-right px-3">{{ $t('yes') }}</b-button>
            </div>
            </b-dropdown-form>
          </b-dropdown>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters } from 'vuex'

export default {
  props: {
    isOwner: {
      required: true,
      type: Boolean
    }
  },
  computed: {
    ...mapGetters([
      'console',
      'lang',
      'user',
      'isAuthenticated',
      'products'
    ])
  },
  methods: {
    destroy (productId) {
      const path = `auth/${this.lang}/console/${this.console}/shop/product/${productId}/delete`
      this.$axios.delete(path).then(response => {
        this.$store.dispatch('SET_DELETE_PRODUCT', productId)
        this.$toastr.success(response.data.message, window.TITLE_SUCCESS)
      })
    }
  }
}
</script>
